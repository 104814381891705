import {
  extensionDealersActions,
  extensionDealersMutations,
  extensionDealersGetters
} from './types'

import { sdk } from 'Services/shelfNetworkSdk'
import { i18n } from 'I18n/instance'
import { LoneSdkCall } from 'Utils/LoneSdkCall'

import { DEALERS_PAGE_LIMIT } from '../constants'
import { DealerNotFoundError, ImpactLoginUpdateError } from '../errors'

import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

const loneSdkCall = new LoneSdkCall()

export default {
  async [extensionDealersActions.LOAD_LIST] ({ commit }, opts = {}) {
    commit(extensionDealersMutations.SET_IS_LOADING, true)
    commit(extensionDealersMutations.SET_LIST_OPTS, opts)

    const { data } = await loneSdkCall.takeLatest(
      sdk.impactAutoBackOffice.getIdentities({
        query: createIdentitiesQuery(opts)
      })
    )

    commit(extensionDealersMutations.SET_LIST, data)
    commit(extensionDealersMutations.SET_PAGE_NUMBER, 0)

    commit(extensionDealersMutations.SET_IS_LOADING, false)
  },

  async [extensionDealersActions.LOAD_EXPORT_LIST] ({ commit, getters }) {
    const opts = getters[extensionDealersGetters.LIST_OPTS]
    const EXPORT_PAGE_LIMIT = 100
    const list = []

    let pageNumber = 0
    while (true) {
      const { data } = await loneSdkCall.takeLatest(
        sdk.impactAutoBackOffice.getIdentities({
          query: {
            ...opts,
            page: {
              number: pageNumber++,
              limit: EXPORT_PAGE_LIMIT
            },
            include: ['basics', 'location']
          }
        })
      )

      list.push(...data)
      if (data.length < EXPORT_PAGE_LIMIT) break
    }

    commit(extensionDealersMutations.SET_EXPORT_LIST, list)
  },

  async [extensionDealersActions.LOAD_EXPORT_LOCATIONS] ({ commit, getters }) {
    const rawLocations = getters[extensionDealersGetters.EXPORT_LIST]
      .map(item => item.location)
      .filter(item => item.id)
    const uniqLocations = uniqBy(rawLocations, 'id')

    const responses = await Promise.all(
      uniqLocations.map(location => {
        return sdk.identity.locations.getAll(
          {
            'filter[country.id]': location.countryId || undefined,
            'filter[city.id]': location.cityId || undefined,
            'filter[region.id]': location.regionId || undefined,
            'page[limit]': 1,
            locale: i18n.locale
          },
          i18n.locale
        )
      })
    )

    const exportLocations = responses.map(res => res.data[0] || {})
      .map((loc, i) => ({
        id: get(uniqLocations, `[${i}].id`),
        city: get(loc, 'relationships.city.name', ''),
        country: get(loc, 'relationships.country.name', '')
      }))
    commit(extensionDealersMutations.SET_EXPORT_LOCATIONS, exportLocations)
  },

  async [extensionDealersActions.LOAD_MORE] ({ commit, getters }) {
    if (getters[extensionDealersGetters.PAGE_NUMBER] === null) return

    commit(extensionDealersMutations.SET_IS_NEXT_LOADING, true)

    const pageNumber = getters[extensionDealersGetters.PAGE_NUMBER] + 1
    const { data } = await loneSdkCall.takeLatest(
      sdk.impactAutoBackOffice.getIdentities({
        query: createIdentitiesQuery({
          ...getters[extensionDealersGetters.LIST_OPTS],
          page: { number: pageNumber }
        })
      })
    )

    commit(extensionDealersMutations.PUSH_LIST, data)
    commit(
      extensionDealersMutations.SET_PAGE_NUMBER,
      data.length < DEALERS_PAGE_LIMIT ? null : pageNumber
    )

    commit(extensionDealersMutations.SET_IS_NEXT_LOADING, false)
  },

  async [extensionDealersActions.LOAD_MANAGED_LOGINS] ({ commit }) {
    commit(extensionDealersMutations.SET_IS_LOADING, true)

    const { data: config } = await loneSdkCall.takeLatest(
      sdk.impactAutoBackOffice.getConfig({
        query: {
          // HACK: Hardcode version for admin panel
          filter: { version: '333.0.0' }
        }
      })
    )

    commit(
      extensionDealersMutations.SET_MANAGED_LOGINS,
      config.allowedToAssignTo
    )
    commit(extensionDealersMutations.SET_IS_LOADING, false)
  },

  async [extensionDealersActions.LOAD_IDENTITY] (
    { commit, dispatch },
    search
  ) {
    const { data } = await sdk.impactAutoBackOffice.getIdentities({
      query: {
        filter: { search },
        page: { limit: 1 },
        include: ['basics', 'location']
      }
    })

    const identity = data[0] || {}
    commit(extensionDealersMutations.SET_IDENTITY, identity)
    dispatch(extensionDealersActions.UPDATE_ITEM, identity)
  },

  async [extensionDealersActions.UPDATE_LOGIN] (_, { email, login }) {
    const { data: identities } = await sdk.impactAutoBackOffice.getIdentities({
      query: {
        filter: { search: email },
        page: { limit: 1 },
        include: ['basics']
      }
    })

    const identity = identities[0]
    const basic = get(identity, 'relationships.basic', {})
    if (!identity || basic.email !== email) {
      throw new DealerNotFoundError()
    }

    if (basic.login === login) return

    try {
      await sdk.impactAutoBackOffice.updateIdentity({
        identityId: identity.id,
        attributes: { login }
      })
    } catch (error) {
      const errorMessage = get(error, 'meta.error')
      throw new ImpactLoginUpdateError(errorMessage)
    }
  },

  async [extensionDealersActions.LOAD_ACCOUNTS_BLACKLIST] ({ commit }) {
    const { data } = await sdk.gateway.getAccountsBlacklist()
    commit(extensionDealersMutations.SET_ACCOUNTS_BLACKLIST, data)
  },

  async [extensionDealersActions.ADD_TO_BLACKLIST] ({ commit }, id) {
    await sdk.gateway.blacklistAccount({
      id,
      state: 'paused',
    })
    commit(extensionDealersMutations.ADD_TO_BLACKLIST, { id })
  },

  async [extensionDealersActions.REMOVE_FROM_BLACKLIST] ({ commit }, id) {
    await sdk.gateway.unblacklistAccount(id)
    commit(extensionDealersMutations.REMOVE_FROM_BLACKLIST, id)
  },

  // PROXIES

  async [extensionDealersActions.REMOVE_ITEM] ({ commit }, id) {
    commit(extensionDealersMutations.REMOVE_ITEM, id)
  },

  async [extensionDealersActions.UPDATE_ITEM] ({ commit }, item) {
    commit(extensionDealersMutations.UPDATE_ITEM, item)
  },

  async [extensionDealersActions.UNSHIFT_ITEM] ({ commit }, item) {
    commit(extensionDealersMutations.UNSHIFT_LIST, item)
  }
}

function createIdentitiesQuery (opts) {
  return {
    ...opts,
    page: {
      ...(opts.page || {}),
      limit: DEALERS_PAGE_LIMIT
    },
    include: ['basics', 'location']
  }
}
